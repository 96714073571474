/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';
import { graphql, Link } from 'gatsby';

import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { wrapMDX } from '../components/MDXComponents';
import { blogMDXComponents } from '../utils/helpers/blog-mdx';

import BaseTemplate from '../components/templates/BaseLayout';
import SEO from '../components/atoms/utility/seo';
import NextPrevLinks from '../components/atoms/blog/NextPrevLinks';
import Spacer from '../components/atoms/utility/Spacer';
import SignUpCallout from '../components/atoms/callouts/SignUpCallout'; // Ensure kept for future use or if part of original layout
import SocialSharing from '../components/atoms/blog/SocialSharing';
import Headline from '../components/atoms/typography/Headline';
import Paragraph from '../components/atoms/typography/Paragraph';
import DownloadAppCTA from '../components/atoms/callouts/DownloadAppCTA';
import Button from '../components/atoms/inputs/Button';
import BusinessApplicant from '../components/organisms/BusinessApplicant';
import JoinTheWaitlist from '../components/organisms/JoinTheWaitlist';

function BlogPostTemplate({ data, pageContext }) {
    const post = data.mdx;
    const recommendations = data.blogRecommendations.edges;
    const { previous, next } = pageContext;
    const localImages = post.frontmatter.embeddedImagesLocal;
    const remoteImages = post.embeddedImagesRemote;
    const openGraphImage = post.openGraphImage || post.headerImage;
    const isAustinBlog = post.frontmatter.tags.includes('austin');
    const isPartnershipBlog = post.frontmatter.tags.includes('partnership');
    const showConnectBusinessForm = post.frontmatter.tags.includes('business');
    const showWaitListForm = post.frontmatter.tags.includes('waitlist');
    const maxWidth = !post.frontmatter.noTitle ? 'max-w-6xl' : 'max-w-4xl';

    return (
        <BaseTemplate>
            <SEO
                title={post.frontmatter.title}
                description={post.frontmatter.description}
                customSeoImage={openGraphImage}
            />
            <div className={`${maxWidth} w-full mx-auto lg:px-4 px-0 my-10`}>
                {!post.frontmatter.noTitle && (
                    <>
                        <h1 className="text-3xl md:text-5xl lg:text-6xl xl:text-[72px] xl:leading-[77px] font-light">
                            {post.frontmatter.title}
                        </h1>
                        <div className="flex-row justify-between my-6">
                            <span className="text-sm font-normal">
                                {post.frontmatter.date}
                            </span>
                            <span className="text-sm font-normal mx-3">/</span>
                            <span className="text-sm font-normal">
                                {`${post.timeToRead} ${
                                    post.timeToRead > 1 ? 'minutes' : 'minute'
                                } reading`}
                            </span>
                        </div>
                    </>
                )}

                <MDXProvider components={blogMDXComponents}>
                    <MDXRenderer remoteImages={remoteImages} localImages={localImages}>
                        {post.body}
                    </MDXRenderer>
                </MDXProvider>

                <div className="flex items-center justify-center mt-8 md:mt-10">
                    <SocialSharing post={post} />
                </div>

                {recommendations.length > 0 && !isPartnershipBlog && (
                    <div className="my-10">
                        <h3 className="mb-2 underline lg:text-lg">
                            Recommended Readings:
                        </h3>
                        <ul>
                            {recommendations.map((recommendation, index) => (
                                <Link key={index} to={recommendation.node.frontmatter.slug} className="text-highlight hover:text-default">
                                    <li className="mb-2">{recommendation.node.frontmatter.title}</li>
                                </Link>
                            ))}
                        </ul>
                    </div>
                )}

                {!isPartnershipBlog && (
                    <NextPrevLinks next={next} previous={previous} />
                )}

                {showConnectBusinessForm && <BusinessApplicant />}
                {showWaitListForm && <JoinTheWaitlist />}

                {!showConnectBusinessForm && !showWaitListForm && isPartnershipBlog && (
                    <>
                        <div>
                            <Headline
                                spanText={'Reach Your Audience'}
                                spanColor="#6A67FF"
                                className="mb-4 text-center"
                            />
                            <Paragraph
                                paragraphText={`Join our partnership program and start growing your audience today.`}
                                className="max-w-xl mx-auto mb-4 md:mb-8 text-grey-100 text-center"
                            />
                            <div className="mx-auto flex items-center justify-center">
                                <Link to="/ambassadors">
                                    <Button label="Join Now" className="w-full" />
                                </Link>
                            </div>
                        </div>
                        <Spacer sizeY={1} />
                    </>
                )}

                {isAustinBlog && !isPartnershipBlog && (
                    <>
                        <div>
                            <Headline
                                spanText={'Download the App'}
                                spanColor="#6A67FF"
                                className="mb-4 text-center"
                            />
                            <Paragraph
                                paragraphText={`Build Your AI Agent Today With No Code - Connect It With Other Agents To Give It Superpowers.`}
                                className="max-w-xl mx-auto mb-4 md:mb-8 text-grey-100 text-center"
                            />
                            <div className="mx-auto flex items-center justify-center w-[min-content]">
                                <DownloadAppCTA id="blog-post" />
                            </div>
                        </div>
                        <Spacer sizeY={1} />
                    </>
                )}

                <Spacer sizeY={1} className="" />
            </div>
        </BaseTemplate>
  );
}

export default BlogPostTemplate;

export const pageQuery = graphql`
    query BlogPostById($id: String!, $category: String!) {
        mdx(id: { eq: $id }) {
            id
            excerpt(pruneLength: 160)
            body
            timeToRead
            headerImage {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
            openGraphImage {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
            embeddedImagesRemote {
                childImageSharp {
                    gatsbyImageData
                }
            }
            frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
                description
                embeddedImagesLocal {
                    childImageSharp {
                        gatsbyImageData
                    }
                }
                slug
                category
                tags
                noTitle
            }
        }
        blogRecommendations: allMdx(
            filter: {
                id: { ne: $id }
                frontmatter: { category: { eq: $category } }
            }
            sort: { order: DESC, fields: frontmatter___date }
            limit: 5
        ) {
            edges {
                node {
                    frontmatter {
                        title
                        slug
                    }
                }
            }
        }
    }
`;
